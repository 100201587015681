<template>
  <div>
    <div class="content">
      <div class="all">
          <div class="tab">
              <el-tabs v-model="activeName" @tab-click="handleClick"  style="margin-left:25px">
                <el-tab-pane label="工作日工作时间设置" name="one"></el-tab-pane>
                <el-tab-pane label="非工作日设置" name="two"></el-tab-pane>
              </el-tabs>
        <div class="table">
         <div v-if="activeName=='one'">
            <el-form ref="form" :model="form" label-width="150px" size="mini" class="form">
            <el-form-item label="上午:">
            <el-time-select 
                placeholder="起始时间"
                v-model="form.amStart"
                :picker-options="{
                start: '07:00',
                step: '00:15',
                end: '12:00'
                }"
                @change="sumTime()">
            </el-time-select>
            至
            <el-time-select
                placeholder="结束时间"
                v-model="form.amEnd"
                :picker-options="{
                start: '07:00',
                step: '00:15',
                end: '12:00'
                }"  @change="sumTime()">
            </el-time-select>
            </el-form-item>
            <el-form-item label="下午:">
            <el-time-select
                placeholder="结束时间"
                v-model="form.pmStart"
                :picker-options="{
                start: '12:00',
                step: '00:15',
                end: '20:00'
                }"  @change="sumTime()">
            </el-time-select>
            至
            <el-time-select
                placeholder="结束时间"
                v-model="form.pmEnd"
                :picker-options="{
                start: '12:00',
                step: '00:15',
                end: '20:00'
                }"  @change="sumTime()">
            </el-time-select>
            </el-form-item>
            <el-form-item label="工作时长:">
            {{this.form.hourLong}}小时
            </el-form-item>
            </el-form>
            <div style="width:50%;text-align: center;">
              <el-button type="primary" @click="goback" size="mini">取 消</el-button>
                <el-button type="primary" @click="saveTime" size="mini">确 定</el-button>
            </div>
        </div>
        <div v-if="activeName=='two'">
            <div>
                <el-form ref="search" :inline="true" :model="search" size="small" >
            <el-form-item label="" class="lab">
            <el-select v-model="search.year" @change="getShow" placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in YearList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="" class="lab">
            <el-button type="primary" @click="getYear()" size="mini">获取法定节假日</el-button>
            </el-form-item>
            <el-form-item label="" class="lab">
            <el-select v-model="search.week" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in dataList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="" class="lab">
            <el-button type="primary" @click="setWork()" size="mini">设为工作日</el-button>
            </el-form-item>
            </el-form>
            </div>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;width:100%;height:calc(100vh - 375px);overflow:auto">
                <div style="width:24%;margin-right:10px;" v-for="itemM,indexM in 12" :key="indexM">
                        <el-calendar  v-model='showdata[indexM]' >
                        <template
                            slot="dateCell"
                            slot-scope="{date, data}">
                            <p :class="data.isSelected ? 'is-selected' : 'no-selected'" @click="addData(data,date)" v-if="data.type=='current-month'"> 
                              <!-- <span v-if="data.day.split('-')[1]==showdata[indexM].split('/')[1]"> -->
                                  {{data.day.split('-')[2]}} 
                                  <span v-for="(item,index) of noworkdata[data.day.split('-')[0]+data.day.split('-')[1]]" :key="index" > 
                                    {{item.date==data.day.split('-')[0]+data.day.split('-')[1]+data.day.split('-')[2]?'✔️':''}}
                                    
                                  </span>
                              <!-- </span> -->
                            </p>
                        </template>
                        </el-calendar>
                </div>
            </div>
            <div style="width:100%;text-align: center;padding:20px">
                <el-button type="primary" @click="gobackDate" size="mini">取 消</el-button>
                <el-button type="primary" @click="saveDate" size="mini">确 定</el-button>
            </div>
            
        </div>
        </div>
         </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import {selectDictListByParentCode } from '@/apis/commonType'
import {bzMessageConfig_getHourLongDetail,bzMessageConfig_saveHourLong,bzMessageConfig_getNotWorkDay,bzMessageConfig_getWorkHoliday,bzMessageConfig_getWeekDay,bzMessageConfig_saveNotWorkDay} from '../../RequestPort/settings/settings'



export default {
  name: 'reportApply',
  components: {},
  mixins: [],
  data() {
    return {
      YearList:[],
      dataList:[{value:1,label:'周六'},{value:2,label:'周日'}],
      activeName:'one',
      form:{hourLong:0},
      search:{},
      showdata:[],
      noworkdata:{},
      gobackList:{},
      data:{}
    }
  },
  mounted() {
      this.getOption();
      this.search.year=this.YearList[1].value
      this.getShow();
      this.getTime();
  },
  methods: {
      getOption(){
          var myDate = new Date();
          var year = myDate.getFullYear();
          this.YearList = [{value:year-1,label:year-1},{value:year,label:year},{value:year+1,label:year+1}]
      },
      sumTime(){
        var shang = 0;var xia = 0;
        if(this.form.amStart>this.form.amEnd||this.form.pmStart>this.form.pmEnd){
          this.$message.error('开始时间大于结束时间');
          return 
        }
        if(this.form.amStart&&this.form.amEnd){
           shang = (new Date('2022-01-01 '+this.form.amEnd) - new Date('2022-01-01 '+this.form.amStart))/ 1000 / 60/60
        }
        if(this.form.pmStart&&this.form.pmEnd){
          xia = (new Date('2022-01-01 '+this.form.pmEnd) - new Date('2022-01-01 '+this.form.pmStart))/ 1000 / 60/60
        }
        this.form.hourLong = shang + xia
         
      },
      getTime(){
        bzMessageConfig_getHourLongDetail({}).then(res=>{
          this.form = res.data
          this.form.amStart = this.form.amStart?this.form.amStart.substr(0,5):''
          this.form.amEnd = this.form.amEnd?this.form.amEnd.substr(0,5):''
          this.form.pmStart = this.form.pmStart?this.form.pmStart.substr(0,5):''
          this.form.pmEnd = this.form.pmEnd?this.form.pmEnd.substr(0,5):''
          this.gobackList = res.data;
        })
      },
      
      goback(){
        this.getTime()
      },
      saveTime(){
       
        bzMessageConfig_saveHourLong(this.form).then(res=>{
          if(res.code==200){
            this.$message.success('设置成功！')
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      handleClick(){

      },
      setWork(){
        if(!this.search.week){
          this.$message.error('请选择周六或周日')
          return ;
        }
        bzMessageConfig_getWeekDay({year:this.search.year,weekType:this.search.week}).then(res=>{
          var dataList = res.data;
          for (var i=1;i<=12;i++){ 
              const month = (Array(2).join(0) + i).slice(-2)
              const monkey = this.search.year+month
              const ylist =[]
              if(this.noworkdata[monkey]){
                ylist = this.noworkdata[monkey];
              }else{
                ylist = [];
              }
              if(dataList[monkey]){
                  dataList[monkey].forEach(el=>{
                  ylist.forEach((yel,index)=>{
                    if(el.date==yel.date){
                       ylist.splice(index,1)
                    }
                  })
                })
                this.$set(this.noworkdata,monkey,ylist);
              }
              console.log(this.noworkdata)
            }
          
        })

      },
      getYear(){
        bzMessageConfig_getWorkHoliday({year:this.search.year}).then(res=>{
          this.noworkdata = res.data;
        })
      },
      addData(obj,date){
        const dateArr = obj.day.split('-');
        const year = dateArr[0];
        var monkey = dateArr[0]+dateArr[1];
        const day = dateArr[0]+dateArr[1]+dateArr[2];
        var monthAll = [];
        if(this.noworkdata[monkey]){
          monthAll = this.noworkdata[monkey];
        }else{
          monthAll = [];
        }
        var flagAdd = 0
        monthAll.forEach((el,index) => {
          if(el.date==day){
            flagAdd = 1
            monthAll.splice(index,1);
          }
        });
        if(flagAdd!=1){
          monthAll.push({"year": year,
          "month": monkey,
          "date": day})
        }
        this.noworkdata[monkey] = monthAll;
      },
      getShow(){
         const year = this.search.year;
          this.showdata = [];
          for (var i=1;i<=12;i++){ 
              const month = (Array(2).join(0) + i).slice(-2)
               this.showdata.push(year+"/"+month+'/01')
            }
         this.getDataList()
      },
      getDataList(){
         bzMessageConfig_getNotWorkDay({year:this.search.year}).then(res=>{
          this.noworkdata = res.data;
        })
      },
      getRange(index){
          const month = index+1;
          const year = this.search.year;
          const firstMonthDate = new Date(year+"/"+month+'/1');
          const first1 = 9-firstMonthDate.getDay();

          const month1 = month +1;
          var curDate= ''
          if(month1==13){
              curDate = new Date(year+1+'/01/1');
          }else{
              curDate = new Date(year+"/"+month1+'/1');
          }
          const preDate = new Date(curDate.getTime() - 24*60*60*1000).getDate();
          const end = preDate-((preDate-first1)%7)-1;
          return [year+'-'+month+'-'+first1,year+'-'+month+'-'+end];
      },
      saveDate(){
        var data = [];
        
         for (var i=1;i<=12;i++){ 
              const month = (Array(2).join(0) + i).slice(-2)
              const monthkey = this.search.year+month;
              if(this.noworkdata[monthkey]){
                  data = data.concat(this.noworkdata[monthkey])
              }
              
            }
            // if(data.length<=0){
            //   this.$message.warning('请选择日期！')
            //   return;
            // }
        bzMessageConfig_saveNotWorkDay(data).then(res=>{
          if(res.code==200){
            this.$message.success('设置成功！')
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      gobackDate(){
        this.getShow()
      }

  }
   

}
</script>

<style  lang="less" scoped>
::v-deep .el-calendar-table .el-calendar-day{height: auto;}
::v-deep .el-button-group{display: none;}
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
::v-deep .el-calendar-table .el-calendar-day{padding:0}
::v-deep .el-calendar-table:not(.is-range) td.prev {pointer-events: none;}
::v-deep .el-calendar-table:not(.is-range) td.next, .el-calendar-table:not(.is-range) td.prev {pointer-events: none;}
.tab{background-color: #fff;border-radius: 6px;margin: 10px;}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.form{height: calc(100vh - 285px);}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin-right: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
.is-selected{width: 100%;height: 40px;padding:8px;
span{display: block;}
}
.no-selected{width: 100%;height: 40px;padding:8px;span{display: block;}}
</style>